@media screen and (max-width: 768px ){
  .vision{
    height: 60vh;
  }
    .navLogo{
      width: 80%;
    }
    .btnNav{
      font-size: 0.9em;
    }
    .navLinks{
      width: 95%;
    }
    .imgMission{
      height: 50%;
      width: 100%;
      position: absolute;
      margin: 0;
    }
    .textMission{
      width: 100%;
      top: 51%;
      text-align: center;
      margin:0;
      position: absolute;
    }
    .spanVision{
      font-size: 36px;
      margin: -10px 0 20px 0;
    } 
    .upperPart{
      font-size: 30px;
    }
    .lowerPart{
      font-size: 14px;
    }
  
    .initiative{
      margin-top: 50vh;
      height: 150vh;
    }
    .gridConatiner{
      height: 80vh;
      width: 95%;
      display: grid;
      grid-template: 1fr 1fr 1fr 1fr 1fr 1fr / 1fr 1fr;
      gap: 6%;
      margin: 0 2.5%;
      grid-template-areas: 
      "1 2"
      "4 5"
      "4 5"
      "4 5"
      "7 8" 
      "10 11";
    }
    .imgIni{
      width: 90%;
    }
    .textImgOne{
      position: absolute;
      margin: -4vh 10px; 
    }
  
    .aboutus{
      height: 80%;
      width: 84%;
      margin: 0 0 100px 8%;
    }
    .honeAU{
      margin: 60px 0 30px 0;
      font-size: 40px;
    }
    .pAU{
      font-size: 20px;
    }
  
    .gridCU{
      width: 92%;
      margin: 0 4vw;
      gap: 2%;
      display: grid;
      grid-template: 1fr 1fr/ 1fr;
      grid-template-areas: 
      "1" 
      "2";
    }
    .formCU{
      width: 70%;
      border: 2px solid black;
      margin: 8px 50px ;
    }
    .formCUb{
      margin: 20px 85px 30px 85px;
      width: 50%;
    }
  
    .gridPilottwo{
      position: absolute;
      padding-top: 0vh;
      margin: 0;
    }
    .imgInitiativeClass{
      width: 100%;
      height: 100%;
      border-radius: 45px;
      margin: 0 0 0 -20px;
    }
  
    .gridPilotgrid{
      margin: 365px 0 0 25px;
    }
    .InitiativesMain{
      height: 80vh;
    }
  
    .divFormCU{
      margin: 20px 0 25px 0;
    }
    .quoteCU{
      text-align: center;
      margin: 5vh 20px 5vh 20px;
      font-weight: bolder;
      font-size: 50px;
    }

    .contactus{
      height: 135vh;
    }

    .footerCR{
      margin-top: 1%;
      font-size: 9px;
    }
    .footerLine{
      border-top: 1.5px solid rgb(64, 64, 64);
      margin-left: 2%;
      width: 96%;
    }

    .lhsArrow ,.rhsArrow{
      height: 30px;
      width: 30px;
    }
    .btnStyles{
      height: 30px;
      width: 30px;
      position: absolute;
      top: 56%;
      transform: translateY(-50%);
      background: none;
      text-decoration: none;
      border: none;
    }
    .ulul{
      display: flex;
      flex-direction: column;
    }
    .divone{
      margin: 10px 0 0 0;
    }
    .divtwo{
      margin: 20px 0 0 -25px;
    }
    .divthree{
      margin: 25px 0 0 0;
    }

    .honeIni{
      margin-top: 60px;
      margin-bottom: 35px;
    }
}