*{
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
  scroll-padding-top: 15vh; /* height of sticky header */
}

button{
  cursor: pointer;
}

h1{
  text-align: center;
  font-size: 50px;
  font-weight: bolder;
}
h2{
  color: rgb(98, 100, 102);
}

.navSec{
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 1;
  overflow: hidden;
}

nav{
  height: 15vh;
  background-color: #fff;
  display: flex;
  justify-content: space-around;
  align-items: center;
  color: white;
}
.navLinks{
  width: 40%;
  display: flex;
  justify-content: space-around;
  list-style: none;
  text-decoration: none;
  align-items: center;
  color: white;
}
.btnNav{
  background: none;
  border: none;
  font-size: 1.25em;
  font-weight: 700;
  color: rgb(238, 161, 18);
  text-decoration: none;
}
.btnNav:hover{
  border-bottom: 2px solid  rgb(241, 133, 8);
}

.mission{
  height: 85vh;
  background: #f97c78;
}
.visionBeforeColor{
  height: 85vh;
  background: #03522d;
}
.vision{
  visibility: visible;
  height: 55vh;
  width: 90vw;
  background-color: rgb(41, 45, 95);
  color: white;
  margin: 4vw;
  text-align: center;
  align-items: center;
  padding-top: 50px;
}
.InitiativesMain{
  height: 100vh;
}
.initiative{
  height: 115vh;
  margin: 0px 4vw;
}
.aboutus{
  height: 55vh;
  width: 60%;
  margin: 0 20%;
}
.contactus{
  height: 80vh;
}
.contactus div div h1{
  margin-top: 5vh;
}
.footer{
  height: auto;
}

.imgMission{
  height: 85vh;
  width: 70%;
  position: absolute;
  margin-left: 30%;
}
.imgInitiativeClass{
  width: 100%;
  height: 75vh;
  border: 2px solid #fff;
  border-radius: 60px;
}
.pIni{
  font-size: 70px;
  font-weight: bolder;
  color: #000;
}
.spanPilotLine{
  margin: 20px;
}
.honeIni{
  margin-bottom: 60px;
}

.textMission{
  position: absolute;
  width: 25%;
  text-align: center;
  margin: 5vh;
}
.spanOne{
  font-size: 50px;
  color: #000;
  font-weight: bolder;
}
.spanTwo{
  font-size: 20px;
  font-weight: bold;
  color: rgb(92, 91, 91);
}
.spanOnee{
  font-size: 55px;
  color: #000;
  font-weight: bolder;
}
.spanTwoo{
  font-size: 20px;
  font-weight: bold;
  color: rgb(136, 148, 160);
}
.btn{
  height: 50px;
  width: 300px;
  border: white;
  border-radius: 7px;
  background-color: rgb(207, 67, 67);
  color: white;
  font-size: 25px;
}
.btn:hover{
  background-color: rgb(148, 46, 46);
  color: white;
}

.ulul{
  display: flex;
  justify-content: space-around;
  text-decoration: none;
  list-style: none;
  text-align: center;
  align-items: center;
}

.forHori{
  display: flex;
}

.forspace{
  margin: 0 50px;
}

.forMargin{
  margin: 0 100px;
}

.imgContatcus{
  height: 55vh;
  width: 80%;
  z-index: -1;
  position: absolute;
  margin: 0 10%;
}

.gridConatiner{
  height: 80vh;
  width: 90vw;
  display: grid;
  grid-template: 1fr 1fr 1fr 1fr / 1fr 1fr 1fr;
  gap: 10px;
  grid-template-areas: 
  "1 2 3"
  "4 5 6"
  "7 8 9" 
  "10 11 12";
}
/*
.gridVision{
  height: 60vh;
  width: 90vw;
  margin: 5vw;
  display: grid;
  grid-template: 1fr 1fr 1fr 2fr 1fr / 1fr 8fr 1fr;
  grid-template-areas: 
  "1 2 3"
  "4 5 6"
  "7 8 9"
  "10 11 12"
  "13 14 15";
}
*/
.gridPilot{
  height: 75vh;
  width: 80vw;
  margin: 0 10vw;
  gap: 10px;
  display: grid;
  grid-template: 1fr/ 1fr 1fr;
  grid-template-areas: 
  "1 2";
}

.gridCU{
  height: 75vh;
  width: 80vw;
  margin: 0 10vw;
  gap: 20px;
  display: grid;
  grid-template: 1fr/ 1fr 1fr;
  grid-template-areas: 
  "1 2";
}
.gridCUgrid{
  background-color: coral;
}

.gridPilotgrid{
  text-align: center;
  align-self: center;
  margin: 100px;
}

.nameIni{
  background-color: thistle;
}

.gridOne{
  background-color: rgb(41, 45, 95);
  color: #fff;
}


.imgIni{
  display: flex;
}

.textImgOne{
  position: absolute;
  margin-top: -150px;
  margin-left: 240px;
}

.upperPart{
  font-size: 3em;
  font-weight: bolder;
}
.lowerPart{
  font-size: 1em;
}
.spanVision{ 
  text-align: center;
  align-items: center;
  font-size: 3em;
  font-weight: bolder;
  margin: 20px 0 75px 0;
}

.honeAU{
  margin: 60px 0;
  font-size: 40px;
}
.pAU{
  font-size:23px;
}

.honeCU{
  margin-bottom: 60px;
}
.pCU{
  margin: 395px 0px 20px 180px;
  font-size: 20px;
}

.slider{
  height: 85vh;
  width: 100%;
  display: flex;
  position: relative;
  overflow: hidden;
  background: rgb(134, 148, 185);
}

.slideArray{
  min-width: 100%;
  height: 100vh;
  transition: 0.3s;
  overflow: hidden;
}

.btnStyles{
  height: 10vh;
  width: 4%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  background-color: cornflowerblue;
  text-decoration: none;
}
#btnLHS{
  left: 0;
}
.lhsArrow ,.rhsArrow{
  height: 100%;
  width: 100%;
}
#btnRHS{
  right: 0;
}

.CULhs{
  position: absolute;
  margin-left: 10%;
  margin-right: 52%;
  height: 50vh;
  border: 2px solid black;
}
.CURhs{
  margin-left: 52%;
  margin-right: 10%;
}

.divFormCU{
  margin-top: 35px;
}

.formCU{
  width: 50%;
  height: 6vh;
  border: 3px solid black;
  margin: 5px 150px;
}
.formCUb{
  margin: 25px 205px;
  width: 30%;
  text-align: center;
}
.quoteCU{
  text-align: center;
  margin-top: 5vh;
  font-weight: bolder;
  font-size: 50px;
}

.footerClass{
  height: 4vh;
  width: 80%;
  margin: 0 10%; 
}
.footerLine{
  width: 80%;
  margin-left: 10%;
  border-top: 2px solid rgb(75, 75, 75);
  border-radius: 32%;
}
.footerCR{
  color: rgb(126, 125, 125);
  text-align: center;
  font-size: 12px;
}
